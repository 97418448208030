import React from "react";
import PopupWithForm from "./PopupWithForm";

function AddPlacePopup(props) {

  const [name, setName] = React.useState("");
  const [link, setLink] = React.useState("");

    function clearFields() {
        setName("");
        setLink("");
    }

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeLink(e) {
        setLink(e.target.value);
    }

    function handleSubmit(e) {
        // Запрещаем браузеру переходить по адресу формы
        e.preventDefault();
    
        // Передаём значения управляемых компонентов во внешний обработчик
        // И вторым аргументом функцию очистки полей ввода
        props.onCreateCard({
            name,
            link,
        }, clearFields);
    } 

    return (
        <PopupWithForm
          title="Новое место"
          name="create"
          form="card"
          onSubmit={handleSubmit}
          onClose={props.onClose}
          isOpen={props.isOpen}
        >
        <input className="popup-form__input
                    popup-form__input_field_name"
                    id="input-name"
                    type="text" 
                    name="popup-form-mesto"
                    onChange={handleChangeName}
                    value={name}
                    placeholder="Название"
                    minLength="2" 
                    maxLength="30"
                    required />
                <span className="form-error" 
                    id="input-name-error" />
                <input className="popup-form__input 
                    popup-form__input_field_description"
                    id="input-url"
                    type="url" 
                    name="popup-form-link"
                    onChange={handleChangeLink}
                    value={link}
                    placeholder="Ссылка на картинку" 
                    required />
                <span className="form-error" id="input-url-error" />
                <button className="popup-form__btn-submit" type="submit" disable="true">Создать</button>
        </PopupWithForm>
      );
    }

export default AddPlacePopup;