import React from "react";
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import api from "../utils/api";
import auth from "../utils/auth";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import Register from './Register';
import Login from './Login';
import Header from './Header.js';
import Main from './Main.js';
import PopupWithForm from './PopupWithForm';
import EditProfilePopup from './EditProfilePopup';
import EditAvatarPopup from './EditAvatarPopup';
import AddPlacePopup from './AddPlacePopup';
import ImagePopup from './ImagePopup';
import InfoTooltip from './InfoTooltip';
import Footer from './Footer';

import avatarIcon from "../images/profile-avatar.png";

function App(props) {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = React.useState(false);
    const [isAddPlacePopupOpen, setIsAddPlacePopupOpen] = React.useState(false);
    const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] = React.useState(false);
    const [isConfirmPopupOpen, setConfirmPopupOpen] = React.useState(false);
    const [isInfoTooltipPopupOpen, setInfoTooltipPopupOpen] = React.useState(false);
    const [selectedCard, setSelectedCard] = React.useState(null);
    const [cards, setCards] = React.useState([]);

    const [registerSucess, setRegisterSucess] = React.useState();

    const [authData, setAuthData] = React.useState();

    const [currentUser, setCurrentUser] = React.useState({
      name: "Жак-Ив Кусто",
      about: "Исследователь океана",
      avatar: avatarIcon,
    });

    // Запрос на регистрацию

    function handleRegister(password, email) {
      auth.register(password, email)
      .then((res) => {
        setRegisterSucess(true);
        props.history.push('/sign-in');
      })
      .catch((err) => {
        console.log(err);
        setRegisterSucess(false);
      })
      .finally(() => setInfoTooltipPopupOpen(true))
    }

    // Запрос на логин по паролю и мэйлу

    function handleLogin(password, email) {
      return auth.login(password, email)
      .then((res) => {
        if (res && res.token) {
          setLoggedIn(true);
          props.history.push('/');

          updateUserData();
        }
      })
      .catch((err) => console.log(err));
    }

    // Выход
    function logOut() {
      localStorage.removeItem('jwt');
      setLoggedIn(false);
    };

    // Когда меняется loggedIn
    React.useEffect(() => {
      const jwt = localStorage.getItem('jwt');
      if (loggedIn && jwt) {
        auth.checkAuth(jwt)
        .then((res) => {
          if (res) {
            setAuthData(res);
            
            updateUserData();
          }
        })
        .catch((err) => console.log(err));
      }
    }, [loggedIn]);

    // Когда перезагружаем страницу
    React.useEffect(() => {
      const jwt = localStorage.getItem('jwt');

      // Посылаем запрос только когда jwt появился
      if (jwt) {
        auth.checkAuth(jwt)
        .then((res) => {
          if (res) {
            setLoggedIn(true);
            props.history.push('/');

            updateUserData();
          }
        })
        .catch((err) => console.log(err));
      }

    }, []);

    function updateUserData() {
      Promise.all([
        api.getUser(),
        api.getCards()
      ])    
      .then((values) => {
          // const [userData, initialCards] = values;
          // !!!
          setCurrentUser(values[0]);
          setCards(values[1]);
      }).catch((err) => console.log(err));
    }

  function handleCardLike(card) {
    const isLiked = card.likes.some(i => i === currentUser._id);

    // Отправляем запрос в API и получаем обновлённые данные карточки
    api.changeLikeCardStatus(card._id, !isLiked)
    .then((newCard) => {
      // Формируем новый массив на основе имеющегося, подставляя в него новую карточку
      const newCards = cards.map((c) => c._id === card._id ? newCard : c);
      // Обновляем стейт
      setCards(newCards);
    }).catch((err) => console.log(err));
  }

  // Возвращаем новый массив с отфильтрованными карточками, где айди карточек не равен той, которую удалили
  function handleCardDelete(card) {
    api.removeCard(card._id)
    .then(() => {
      setCards((c) => {
        return c.filter(((i) => i._id !== card._id));
      });
    }).catch((err) => console.log(err));
  }

    function handleUpdateUser(data) {
      api.setUser(data)
      .then((data) => {
        setCurrentUser(data);
        closeAllPopups();
      }).catch((err) => console.log(err));
    }

    function handleUpdateAvatar(data) {
      api.updateAvatar(data)
      .then((data) => {
        setCurrentUser(data);
        closeAllPopups();
      }).catch((err) => console.log(err));
    }

    // Обновляем стейт с помощью оператора расширения
    const handleCreateCard = (card, clear) => {
      api.createCard(card)
      .then((newCard) => {
        setCards([newCard, ...cards]);
        clear();
        closeAllPopups();
      }).catch((err) => console.log(err));
    };

    function handleEditProfileClick() {
      setIsEditProfilePopupOpen(true);
    }
  
    function handleAddPlaceClick() {
      setIsAddPlacePopupOpen(true);
    }

    function handleEditAvatarClick() {
      setIsEditAvatarPopupOpen(true);
    }

    function closeAllPopups() {
      setIsEditAvatarPopupOpen(false);
      setIsAddPlacePopupOpen(false);
      setIsEditProfilePopupOpen(false);
      setConfirmPopupOpen(false);
      setInfoTooltipPopupOpen(false);
      
      setSelectedCard(null);
    }

    // При клике на картинку меняем стейт SelectedCard на Card
    const handleCardClick = (card) => {
      setSelectedCard(card);
    };
    
  return (
      <CurrentUserContext.Provider value={currentUser}>
        <EditProfilePopup
          isOpen={isEditProfilePopupOpen}
          onClose={closeAllPopups}
          onUpdateUser={handleUpdateUser}
        />

        <AddPlacePopup 
          isOpen={isAddPlacePopupOpen} 
          onClose={closeAllPopups}
          onCreateCard={handleCreateCard}
        />

        <EditAvatarPopup 
          isOpen={isEditAvatarPopupOpen} 
          onClose={closeAllPopups} 
          onUpdateAvatar={handleUpdateAvatar}
        />

        <InfoTooltip
          isOpen={isInfoTooltipPopupOpen}
          onClose={closeAllPopups}
          success={registerSucess}
        />
        <PopupWithForm
          title="Вы уверены?"
          name="confirm"
          onClose={closeAllPopups}
          isOpen={isConfirmPopupOpen}
        >
          <button className="popup-form__btn-submit" type="submit">Да</button>
        </PopupWithForm>

        <ImagePopup
          card={selectedCard}
          name="image"
          onClose={closeAllPopups}
          isOpen={selectedCard}
        >
        </ImagePopup>

          <div className="page">
            <Header 
              authData={authData} 
              loggedIn={loggedIn} 
              onLogout={logOut}/>
            <Switch>
              <Route path='/sign-up'>
                <Register
                  onRegiter={handleRegister} />
              </Route>
              <Route path='/sign-in'>
                <Login setLoggedIn={setLoggedIn} onLogin={handleLogin}/>
              </Route>
              
              <ProtectedRoute exact path="/"
                loggedIn={loggedIn}
                component={Main}
                onEditProfile={handleEditProfileClick}
                onAddPlace={handleAddPlaceClick}
                onEditAvatar={handleEditAvatarClick}
                onCardClick={handleCardClick}
                onCardLike={handleCardLike}
                onCardDelete={handleCardDelete}
                cards={cards}
                >
                {loggedIn ? <Redirect to="/" /> : <Redirect to="/sign-in" />}
              </ProtectedRoute>
              </Switch>
            <Footer />
        </div>
      </CurrentUserContext.Provider>
  );
}

export default withRouter(App);
