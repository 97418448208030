import React from "react";
import PopupWithForm from "./PopupWithForm";
import { CurrentUserContext } from "../contexts/CurrentUserContext";

function EditProfilePopup(props) {
    // Подписка на контекст
    const currentUser = React.useContext(CurrentUserContext);

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');

    React.useEffect(() => {
        if (currentUser) {
            setName(currentUser.name);
            setDescription(currentUser.about);
        }
    }, [currentUser]);
    
    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeDescription(e) {
        setDescription(e.target.value);
    }

    function handleSubmit(e) {
        // Запрещаем браузеру переходить по адресу формы
        e.preventDefault();
        // console.log(e, name, description);
      
        // Передаём значения управляемых компонентов во внешний обработчик
        props.onUpdateUser({
          name,
          about: description,
        });
      } 

    return (
        <PopupWithForm
          title="Редактировать профиль"
          name="edit"
          form="profile"
          onSubmit={handleSubmit}
          // вызываем по имени пропса из app.js а именно по имени onClose
          onClose={props.onClose}
          isOpen={props.isOpen}
        >
          <input className="popup-form__input 
                    popup-form__input_field_name"
                    id="input-name-city"
                    type="text"
                    name="popup-form-name"
                    onChange={handleChangeName}
                    value={name}
                    required 
                    minLength="2" 
                    maxLength="40"/>
                <span className="form-error" 
                    id="input-name-city-error"/>
                <input className="popup-form__input 
                    popup-form__input_field_description" 
                    id="input-about"
                    type="text" 
                    name="popup-form-about"
                    onChange={handleChangeDescription}
                    value={description}
                    required 
                    minLength="2" 
                    maxLength="200" />
                <span className="form-error" id="input-about-error" />
                <button className="popup-form__btn-submit" type="submit" disable="true">Сохранить</button>
        </PopupWithForm>
      );
    }

export default EditProfilePopup;