import React from "react"
import { withRouter } from 'react-router-dom';

function Login(props) {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onLogin(password, email);
  }
      
  return (
    <div>
      <form
        name='login'
        onSubmit={handleSubmit}
        className="auth-form"
        noValidate>
        <h2 className="auth-form__title">Вход</h2>
        <input
          name="email"
          className="popup-form__input popup-form__input_type_auth"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <span className="form-error"></span>
        <input
          name="password"
          onSubmit={handleSubmit}
          className="popup-form__input popup-form__input_type_auth"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Пароль"
          autoComplete="on"
        />
        <span className="form-error"></span>
        <button
          type="submit"
          className="popup-form__btn-submit popup-form__btn-submit_theme_white
          type_signin">
          Войти
          </button>
      </form>
    </div>
  );
}

export default withRouter(Login);
