class ApiAuth {
  constructor(options) {
    this._baseUrl = options.baseUrl;
    this._headers = options.headers;
  }

  // Функция проверки запроса

  _checkResult(res) {
    return res.ok ? res.json() : Promise.reject(`Ошибка: ${res.status}`);
  }
  
    // Регистрация
    register (password, email) {
      return fetch(`${this._baseUrl}/signup`, {
        method: "POST",
        headers: this._headers,
        body: JSON.stringify({password, email}),
      }).then((res) => {
        return this._checkResult(res);
      });
    }

    login(password, email) {
      return fetch(`${this._baseUrl}/signin`, {
        method: "POST",
        headers: this._headers,
        body: JSON.stringify({password, email}),
      }).then((response => response.json()))
      .then((data) => {
        if (data.token) {
          localStorage.setItem('jwt', data.token);
          return data;
        }
      })
      .catch(err => console.log(err))
    }

    checkAuth(jwt) {
      return fetch(`${this._baseUrl}/users/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${jwt}`
        },
      }).then((res) => {
        return this._checkResult(res);
      });
    }
}

export default new ApiAuth({
  baseUrl: "https://api.mesto.hitomizavr.com",
  headers: {
    'Accept': 'application/json',
    "Content-Type": "application/json",
  }
  // {
  // baseUrl: "https://auth.nomoreparties.co",
  // headers: {
  //   authorization: "0eeb4c96-bb84-4d08-b5d5-7ff8bde46738",
  //   'Accept': 'application/json',
  //   "Content-Type": "application/json",
  // },
});
