import React from "react";
import PopupWithForm from "./PopupWithForm";
import successStatus from '../images/successstatus.svg';
import failStatus from '../images/failstatus.svg';

function InfoTooltip(props) {

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <PopupWithForm
          name="info"
          onSubmit={handleSubmit}
          onClose={props.onClose}
          isOpen={props.isOpen}
        >
      <div className="popup-information">
        <img className='popup__image' src={props.success ? successStatus : failStatus} alt='Статус ответа'/>
        <h3 className="popup__title popup__title_status">{props.success ? 'Вы успешно зарегистрировались!' : 'Что-то пошло не так! Попробуйте ещё раз.'}</h3>
        <button 
            className="popup__btn-close"
            alt="Кнопка закрытия"
            onClick={props.onClose}
        />
      </div>
        </PopupWithForm>
      );
    }

export default InfoTooltip;