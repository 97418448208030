import { Link } from 'react-router-dom';
import logo from '../images/header-logo_white.svg';

function Header(props) {

  return (
    <header className="header">
      <Link to="/" className="header__nav-link">
        <img className="header__logo"
        src={logo}
        alt="Логотип сайта" />
      </Link>
      <div className="header__nav-container">
        {props.loggedIn && props.authData ? (
          <>
            <p className="header__nav-link">{props.authData.email}</p>
            <button onClick={props.onLogout} className="header__nav-link header__nav-link_logout">Выход</button>
          </>
        ) : (
          <>
            <Link to="/sign-in" className="header__nav-link">Вход</Link>
            <Link to="/sign-up" className="header__nav-link">Регистрация</Link>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
