import React from 'react';
import { Link } from 'react-router-dom';

function Register(props) {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onRegiter(password, email);
  }

  return (
    <div>
      <form
        name='login'
        onSubmit={handleSubmit}
        className="auth-form"
        noValidate>
        <h2 className="auth-form__title">Регистрация</h2>
        <input
          name="email"
          className="popup-form__input popup-form__input_type_auth"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <span className="form-error"></span>
        <input
          name="password"
          className="popup-form__input popup-form__input_type_auth"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Пароль"
          autoComplete="on"
        />
        <span className="form-error"></span>
        <button
          type="submit"
          className="popup-form__btn-submit popup-form__btn-submit_theme_white">
          Зарегистрироваться
          </button>
        <Link to="/sign-in" className="auth-form__link">Уже зарегистрированы? Войти</Link>
      </form>
    </div>
  );
}

export default Register;
