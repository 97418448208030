import React from "react";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import editIcon from "../images/profile-edit-image.svg";
import Card from "./Card";

function Main(props) {
  const currentUser = React.useContext(CurrentUserContext);

  return (
    <>
      <main className="content">
        <section className="profile">
          <div className="profile__wrappers">
            <div
              className="profile__avatar-overlay"
              onClick={props.onEditAvatar}
            >
              <img className="profile__edit-overlay" src={editIcon} alt="" />
            </div>
            <img
              className="profile__avatar"
              src={currentUser.avatar}
              alt="Аватар профиля"
            />
          </div>
          <div className="profile__wrapper">
            <div className="profile-info">
              <div className="profile-info__wrapper">
                <h1 className="profile-info__name">{currentUser.name}</h1>
                <button
                  className="profile-info__btn-edit"
                  type="button"
                  onClick={props.onEditProfile}
                ></button>
              </div>
              <p className="profile-info__description">{currentUser.about}</p>
            </div>
            <button
              className="profile__btn-add"
              type="button"
              onClick={props.onAddPlace}
            ></button>
          </div>
        </section>
        <section className="photogallery">
          <ul className="photogallery__wrapper">
            {props.cards.map((item) => {
              return (
                <Card
                  key={item._id}
                  card={item}
                  onCardClick={props.onCardClick}
                  onCardLike={props.onCardLike}
                  onCardDelete={props.onCardDelete}
                />
              );
            })}
          </ul>
        </section>
      </main>
    </>
  );
}

export default Main;
