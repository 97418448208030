import React from "react";

function ImagePopup(props) {
    React.useEffect(() => {
        // console.log("component mount");
        document.addEventListener('keydown', handleEscClose);

        return () => {
            document.removeEventListener('keydown', handleEscClose);
            // console.log("component umount");
        };
    }, []);

    // Функция закрытия по ESC

    const handleEscClose = (event) => {
        if (event.key === 'Escape') {
            props.onClose();
        }
    };

    // Функция закрытия по overlay

    const handleOverlayClose = (event) => {
        if (event.target.classList.contains('popup')) {
            props.onClose();
        }
    };

    return (
        <section
            className={`popup popup-${props.name}${props.isOpen ? ' popup_opened' : ''}`}
            onClick={handleOverlayClose}
        >
                <div className={`popup-image__container`}>
                <div className="popup-image__wrapper">
                    <button 
                    className="popup__btn-close popup__btn-close_type_image"
                    alt="Кнопка закрытия"
                    onClick={props.onClose}
                    />
                    <img className="popup-image__image" src={props.card ? props.card.link : ""} alt={props.card ? props.card.name : ""} />
                    <h3 className="popup-image__caption">{props.card ? props.card.name : ""}</h3>
                </div>
            </div>
        </section>
      );
}

export default ImagePopup;