import React from "react";

function PopupWithForm(props) {
    React.useEffect(() => {
        // console.log("component mount");
        document.addEventListener('keydown', handleEscClose);

        return () => {
            document.removeEventListener('keydown', handleEscClose);
            // console.log("component umount");
        };
    }, []);

    // Функция закрытия по ESC

    const handleEscClose = (event) => {
        if (event.key === 'Escape') {
            props.onClose();
        }
    };

    // Функция закрытия по overlay

    const handleOverlayClose = (event) => {
        if (event.target.classList.contains('popup')) {
            props.onClose();
        }
    };

    return (
        <section
        className={`popup popup-${props.name}${props.isOpen ? ' popup_opened' : ''}`}
        onClick={handleOverlayClose}
        noValidate>
            <div className={`popup__container`}>
                {props.title && <h2 className="popup__title">{props.title}</h2>}
                <button
                    className="popup__btn-close" 
                    type="button"
                    onClick={props.onClose}
                />
                <form
                    className={`popup-form popup-form-${props.form}`}
                    name={props.name}
                    onSubmit={props.onSubmit}
                    noValidate
                >
                    {props.children}
                </form>
            </div>
        </section>
      );
    }

export default PopupWithForm;