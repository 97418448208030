import React from "react";
import PopupWithForm from "./PopupWithForm";

function EditProfilePopup(props) {
    const avatarRef = React.useRef();

    function handleSubmit(e) {
        e.preventDefault();
      
        props.onUpdateAvatar({
          avatar: avatarRef.current.value,
        });
      } 

    return (
        <PopupWithForm
          title="Обновить аватар"
          name="avatar"
          form="avatar"
          onClose={props.onClose}
          isOpen={props.isOpen}
          onSubmit={handleSubmit}
        >
        <input className="popup-form__input popup-form__input_field_avatar"
                    id="input-avatar"
                    name="avatar"
                    ref={avatarRef}
                    type="url" 
                    placeholder="Ссылка на картинку"
                    required />
                <span className="form-error" id="input-avatar-error"></span>
                <button className="popup-form__btn-submit" type="submit" disable="true">Сохранить</button>
        </PopupWithForm>
      );
    }

export default EditProfilePopup;