class Api {
  constructor(options) {
    this._baseUrl = options.baseUrl;
    this._headers = options.headers;

  }

  // Функция проверки запроса

  _checkResult(res) {
    return res.ok ? res.json() : Promise.reject(`Ошибка: ${res.status}`);
  }

  _getHeaders() {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      return { ...this._headers, ...{ Authorization: `Bearer ${jwt}`} }
    } else {
      return this._headers;
    }
  }
  
  getUser() {
    return fetch(`${this._baseUrl}/users/me`, {
      headers: this._getHeaders(),
    }).then((res) => {
      return this._checkResult(res);
    });
  }

  getCards() {
    return fetch(`${this._baseUrl}/cards`, {
      headers: this._getHeaders(),
    }).then((res) => {
      return this._checkResult(res);
    });
  }

  setUser(data) {
    return fetch(`${this._baseUrl}/users/me`, {
      method: "PATCH",
      headers: this._getHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      return this._checkResult(res);
    });
  }

  createCard(data) {
    return fetch(`${this._baseUrl}/cards`, {
      method: "POST",
      headers: this._getHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      return this._checkResult(res);
    });
  }

  removeCard(cardId) {
    return fetch(`${this._baseUrl}/cards/${cardId}`, {
      method: "DELETE",
      headers: this._getHeaders(),
    }).then((res) => {
      return this._checkResult(res);
    });
  }

  // Метод-запрос на проверку лайка, если isLiked - то 1 запрос, иначе - 2.
  changeLikeCardStatus(cardId, isLiked) {
    if (isLiked) {
      return fetch(`${this._baseUrl}/cards/${cardId}/likes`, {
        method: "PUT",
        headers: this._getHeaders(),
        }).then((res) => {
          return this._checkResult(res);
        });
    } else {
      return fetch(`${this._baseUrl}/cards/${cardId}/likes`, {
        method: "DELETE",
        headers: this._getHeaders(),
        }).then((res) => {
          return this._checkResult(res);
        });
    }
  }

  updateAvatar(data) {
    return fetch(`${this._baseUrl}/users/me/avatar`, {
      method: "PATCH",
      headers: this._getHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      return this._checkResult(res);
    });
  }
}

export default new Api({
  baseUrl: "https://api.mesto.hitomizavr.com",
  headers: {
    // 'Accept': 'application/json',
    "Content-Type": "application/json",
  }
// {
//   baseUrl: "https://mesto.nomoreparties.co/v1/cohort-19",
//   headers: {
//     authorization: "0eeb4c96-bb84-4d08-b5d5-7ff8bde46738",
//     "Content-Type": "application/json",
//   },
}
);


