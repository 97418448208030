import React from "react";
import { CurrentUserContext } from "../contexts/CurrentUserContext";

function Card({ card, onCardClick, onDeleteCard, onCardLike, onCardDelete }) {
  const currentUser = React.useContext(CurrentUserContext);
  const isOwn = card.owner === currentUser._id;

    // Определяем, есть ли у карточки лайк, поставленный текущим пользователем
  const isLiked = card.likes.some(i => i === currentUser._id);

  function handleLikeClick() {
    onCardLike(card);
  }

  function handleDeleteClick() {
    onCardDelete(card);
  }

  return (
    <li className="photo-card">
      {isOwn ? (<button
        className="photo-card__btn-remove"
        type="button"
        onClick={handleDeleteClick}
      />) : null}
      <img
        className="photo-card__image"
        src={card.link}
        onClick={() => onCardClick(card)}
        alt={card.name}
      />
      <div className="photo-card__wrapper">
        <h2 className="photo-card__title">{card.name}</h2>
        <div className="photo-card__like-wrapper">
          <button className={`photo-card__btn-like ${isLiked ? "photo-card__btn-like_active" : ""}`} onClick={handleLikeClick} type="button" />
          <span className="photo-card__like-count">{card.likes.length}</span>
        </div>
      </div>
    </li>
  );
}

export default Card;
